import * as React from 'react'
import { omit } from 'lodash-es'

import { CourseFrameworkInput } from 'app2/api'
import { CheckboxField, Dropdown, InputField, Form, FormModel, Modal, Section, TextAreaField, useForm, NEW_ITEM_PREFIX } from 'app2/components'
import { CourseTagsDropdown, useCourseTagsQuery } from 'app2/views/shared-public'
import { CourseCardImageField, CourseImageField } from 'app2/views/shared'

import { upsertCourseFramework, useCompanyFrameworkQuery } from './generated'

interface Props {
  company:string;
  framework?:string;
  name?:string
}

export function CourseFrameworkModal(props:Props) {
  const [result] = useCompanyFrameworkQuery({variables:{id: props.framework}});
  const creating = !props.framework;
  const framework = result.data?.courseFramework;
  const form = useForm<CourseFrameworkInput>(() => {
    return {
      ...omit(framework, 'courseTags'), 
      companyId: props.company,
      name: framework?.name || props.name,
      id: props.framework || NEW_ITEM_PREFIX,
      courseTagIds: framework?.courseTags?.map(t => t.id) ?? [],
      public: framework?.public ?? true
    }
  }, [result.data]);

  // we load these so we can return the course tag name
  // which is needed by the activities table, and we can't
  // use the result from the server because of some odd
  // interaction between returning course tags and that
  // causing the courses query to refire while in edit mode
  // see https://airtable.com/app28k8NqnFYDV9GY/tblLpjVgfZL3mJvSs/viwep3IPmQMiMEtWp/rec8Ff1VRDUUE4PvD?blocks=hide
  
  const [tagsResult] = useCourseTagsQuery();
  const courseTags = tagsResult.data?.courseTags;

  function render() {
    return <Modal title={creating ? 'Add activity template' : 'Edit activity template'}>
      <Form title={form.values?.name} subtitle={creating ? 'An activity template is a blueprint for your classes. Fill in essential details now, so you won’t have to start from scratch next time.' : ''} form={form} editing onOk={onSaveFramework}>
        <Section name='name' label='Name' component={InputField} required />
        <Section name='courseTagIds' label='Category' component={CourseTagsDropdown} required format={(ids:string[]) => ids[0]} parse={(id:string) => [id]} />
        <Section name='description' label='Description' component={TextAreaField} required />
        <Section name='supplies' label='Supplies list' component={Dropdown} multiple additions enterToggleSelects={false} />
        <CourseImageField companyId={props.company} />
        <CourseCardImageField companyId={props.company} />
        <Section name='public' label='Public' component={CheckboxField} />
      </Form>
    </Modal>
  }

  async function onSaveFramework(form:FormModel<CourseFrameworkInput>) {
    const [success, result] = await upsertCourseFramework({variables:{items:[form.values]}})

    // returns the new/updated framework in a format that is compatible with what 
    // the activity form needs to add it to the list of frameworks
    const framework = {
      ...form.values,
      ...result.data?.courseFrameworksUpsert?.items?.[0],
      courseTags: form.values.courseTagIds?.map(id => ({id, name: courseTags.find(t => t.id == id)?.name})),
      companyId: props.company,
    }

    return success ? framework : false;
  }

  return render();
}
