import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CommonCompanyFragmentDoc } from '../../shared/generated/commonCompany.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyFrameworksQueryVariables = Types.Exact<{
  company: Types.Scalars['ID'];
}>;

export type CompanyFrameworksQuery = {
  company?:
    | {
        id: string;
        name?: string | null | undefined;
        userCompanyRole?: Types.MemberRole | null | undefined;
        nonPartnerApproved?: boolean | null | undefined;
        courseFrameworks?:
          | Array<{
              id: string;
              name: string;
              description: string;
              lastUsedCourse?: { startDate?: string | null | undefined; season: { id: string; name: string; site: { id: string; slug: string; name?: string | null | undefined; partner: boolean } } } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CompanyFrameworksDocument = gql`
  query CompanyFrameworks($company: ID!) @urql(additionalTypenames: ["CourseFramework"]) {
    company(company: $company) {
      ...commonCompany
      courseFrameworks(sorts: [{ name: "name", direction: ASC }]) {
        id
        name
        description
        lastUsedCourse {
          startDate
          season {
            id
            name
            site {
              id
              slug
              name
              partner
            }
          }
        }
      }
    }
  }
  ${CommonCompanyFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type CompanyFrameworksArgs = MakeOptional<UseQueryArgs<CompanyFrameworksQueryVariables, CompanyFrameworksQuery>, 'query'>;

export function useCompanyFrameworksQuery(options: CompanyFrameworksArgs = {}) {
  return useQuery<CompanyFrameworksQueryVariables, CompanyFrameworksQuery>({ query: CompanyFrameworksDocument, ...options });
}

export type CompanyFrameworksOptions = ExecQueryOptions<CompanyFrameworksQueryVariables>;

export function companyFrameworks(options: CompanyFrameworksOptions) {
  return executeQuery<CompanyFrameworksQuery, CompanyFrameworksQueryVariables>(CompanyFrameworksDocument, options);
}

export type CompanyFrameworksSelections = CompanyFrameworksQuery['company'];

(CompanyFrameworksDocument as any).additionalTypenames = ['CourseFramework'];
