import * as React from 'react'
import { difference, omit } from 'lodash-es'

import { EntityKind, Member, MemberRole, OrganizerRole, CompanyRole, FamilyRole, ParticipantInput } from 'app2/api';
import { assignId, Button, ButtonStripButton, DateLabelField, DropdownField, EmailField, Field, FieldInfo, FieldRendererProps, Form, FormContent, FormModel, FormProps, InputField, isNewId, Modal, Panel, PhoneField, RepeatingSection, RepeatingSectionProps, SaveableResult, Section, RadioGroup, useForm, useFormInfo } from 'app2/components';
import { diffArrays, removeQueryFields } from 'app2/views/shared';
import { useCurrentUser } from 'app2/views/shared-public';

import { teamInviteResend, teamSetAsContact, TeamParticipantsSelections, teamParticipantsUpsert, useTeamParticipantsQuery, TeamParticipantsDocument } from './generated';

// UnifiedTeam is a newer implementation of Team that provides a combined
// view of team members, contacts, and invites.

export const allTeamCols = ['role', 'isContact', 'sentAt', 'resendInvite', 'relation', 'phone', 'email', 'attendanceCodeCode'];
const nonInputCols = ['attendanceCodeCode', 'isContact', 'sentAt', 'resendInvite', 'isCurrentUser'];
type Participant = TeamParticipantsSelections['participants'][0];

export interface UnifiedTeamGroup extends Partial<RepeatingSectionProps<any>> {
  title:React.ReactNode;
  header?:React.ReactNode
  contacts?:boolean;
  cols?:typeof allTeamCols;
}

export interface UnifiedTeamProps extends Partial<Omit<RepeatingSectionProps<any>, 'autoSave' | 'onChange'>>, Pick<FormProps, 'form' | 'editing' | 'onNavigation' | 'autoSave'> {
  entityKind:EntityKind;
  entityId:string;
  team?:TeamParticipantsSelections;
  group?:(m:Member) => number;
  groups?:UnifiedTeamGroup[] | ((team:Participant[]) => UnifiedTeamGroup[]);
  cols?:typeof allTeamCols;
  panel?:boolean;
}

export function UnifiedTeam(props:UnifiedTeamProps) {
  const {entityKind, entityId, team:propsTeam, group, groups:propsGroups, cols, panel, form:propsForm, editing:propsEditing, onNavigation, autoSave, ...remaining} = props;

  const { user } = useCurrentUser();

  const {team, participants, segmentedTeam} = getTeam();
  const outerFormInfo = useFormInfo();
  const editing = team?.teamParticipantsUpsert && Boolean(propsEditing || outerFormInfo?.editing);

  const attendanceCodesExist = participants?.find(m => !!m.attendanceCodeCode);
  const groups = React.useMemo(() => typeof propsGroups == 'function' ? propsGroups(participants) : propsGroups, [participants, propsGroups]);

  const members = participants?.filter(m => m.kind == 'member') || [];
  const hasMultipleMembers = members.length > 1;
  const canRemove = team?.teamParticipantsUpsert;

  const form = useForm(segmentedTeam, [segmentedTeam], {existing:propsForm})

  function render() {
    return panel ? renderPanel() : renderForm();
  }

  function renderPanel() {
    return <Panel icon="Users" title="Team members" type='edit-no-save-button' onNavigation='nothing' primaryActions={renderAdd()}>
      {renderForm()}
    </Panel>
  }

  function renderForm() {
    return <Form<typeof segmentedTeam> form={form} onOk={onSave} autoSave={autoSave ?? true} onNavigation={onNavigation} autoFocus={false} editing={editing}>
      {groups.map(renderGroup)}
    </Form>
  }

  function renderAdd() {
    return team && editing && groups[0].add == 'modal' && <Button icon='Plus' onClick={onAdd}>Add team member</Button>;
  }

  function renderGroup(group:UnifiedTeamGroup, index:number) {
    const colsSet = new Set(group.cols || cols);
    const groupName = 'g' + index;
    const {title, contacts, header, add, cols:groupCols, ...remainingGroup} = group;
    const buttonCols = Number(canRemove) + Number(colsSet.has('resendInvite'));
    const {isNameRequired, isEditable, allowRemove} = React.useMemo(() => getCallbacks(), [editing, team]);

    return <FormContent key={index} subtitle={title} style={{wordBreak: 'break-word'}} data-test={title}>
      {header}
      <RepeatingSection name={groupName} onRemove='_destroy' buttonCols={buttonCols} add={add != 'modal' && add} {...remaining} {...remainingGroup} fields={[
        <Field label="Name" name="name" component={InputField} required={isNameRequired} editing={isEditable} />,
        colsSet.has('relation') && <Field label="Relationship" name="relation" component={InputField} editing={isEditable} required={contacts} placeholder='e.g. Mom, dad, friend, nanny' />,
        colsSet.has('phone') && <Field label="Phone" name="phone" component={PhoneField} required={contacts} editing={isEditable} />,
        colsSet.has('email') && <Field label="Email" name="email" component={EmailField} required={!contacts} editing={isEditable} />,
        colsSet.has('attendanceCodeCode') && attendanceCodesExist && <Field label="Attendance code" name="attendanceCodeCode" />,
        colsSet.has('role') && <Field label="Role" name="role" component={DropdownField} required options={roleOptions} editing={editing && team?.teamChangeRole} />,
        colsSet.has('isContact') && hasMultipleMembers && (!contacts && team.teamSetAsContact ? <Field label="Main contact" name="isContact" format={renderSetAsContact}  /> : <Field  />),
        colsSet.has('sentAt') && <Field label="Invite sent" name="sentAt" component={DateLabelField} editing={false} none={false} />,
        colsSet.has('resendInvite') && <Field name="sentAt" render={(props: FieldRendererProps) => (!props.info.value ? '' : <Button kind="tertiary" icon="RefreshCw" autoLoader onClick={event => onResend(props.info.record)} />)} />,
        canRemove && <Field name='remove' render={(props: FieldRendererProps) => allowRemove(props.info.value)} onRemove={onRemove} />
      ]} />
    </FormContent>
  }

  function renderSetAsContact(isContact:boolean, _:any, info:FieldInfo<Member>) {
    if (info.record.kind != 'member' || info.record.role != MemberRole.Admin) {
      return <ButtonStripButton icon='CheckCircle' small autoLoader opacity={0} pointerEvents='none' />;
    }

    return isContact
      ? <ButtonStripButton icon='CheckCircle' small autoLoader selected={true} />
      : <ButtonStripButton icon='Circle' small autoLoader onClick={() => setAsContact(info.record)} />
  }

  function getCallbacks() {
    function isNameRequired(info:FieldInfo<Member>) {
      return editing && info.record.kind != 'invite';
    }

    function isEditable(info:FieldInfo<Member>) {
      return editing && (info.record.kind != 'member' || isNewId(info.record.id) || info.name == 'relation' || (info.name == 'phone' && info.record.isCurrentUser));
    }

    function allowRemove(participant:Participant) {
      if (participant.kind != 'member') {
        return true;
      }

      if (roleValues[participant.role] > roleValues[team.userMemberRole]) {
        return false;
      }

      const isLastUser = members.length < 2;

      if (isLastUser) {
        return false;
      }

      const isCurUser = participant.email === user?.email;

      if (isCurUser) {
        return false;
      }

      return true;
    }

    return {isNameRequired, isEditable, allowRemove}
  }

  async function onAdd() {
    const group = groups[0];

    const result = await Modal.show<Member>(
      <Modal title="Add team member" ok='Send invite'>
        <Form icon="User" title="Team member details" initialValues={group.defaultRecord}>
          <Section label="Name" name="name" required component={InputField} />
          <Section label="Email address" name="email" required component={EmailField} />
          <Section label="Select role" name="role" component={RadioGroup} options={roleOptions} required editing={team?.teamChangeRole} />
        </Form>
      </Modal>
    );

    if (result.action == SaveableResult.cancel) {
      return;
    }

    const memberInput = result.result;
    const member = assignId({...group.defaultRecord, ...memberInput});
    const members = (form.values['g0'] || []).slice();
    members.push(member);

    form.setValue('g0', members);
  }

  async function onRemove(participant:Participant) {
    const title = 'Remove';
    const name = participant.name || participant.email || participant.phone;
    const content = `Are you sure you want to remove ${name}?`;
    const result = await Modal.warning({ title, content }, true);

    return result.action == SaveableResult.ok;
  }

  async function onSave(form:FormModel<typeof segmentedTeam>) {
    const updated = [].concat(...Object.values(form.values));
    const changed = diffArrays(participants, updated).all.map(m => (omit(m, nonInputCols)));

    if (!changed.length) {
      return true;
    }

    const [success] = await teamParticipantsUpsert({variables:{entityKind, entityId, participants: changed as ParticipantInput[]}});
    return success;
  }

  async function onResend(member: Member) {
    event.preventDefault();

    teamInviteResend({ variables:{entityKind, entityId, member: member.id}, successMsg: 'Invite sent' });
  }

  async function setAsContact(member: Member) {
    const [success] = await teamSetAsContact({variables:{entityKind, entityId, member: member.id}});

    return success;
  }

  function getTeam() {
    const removeFields = React.useMemo(() => getTeamFielsToOmit(props.cols || allTeamCols), [props.cols || allTeamCols]);
    const [result] = useTeamParticipantsQuery({ variables: { entityKind, entityId }, removeFields, pause: !entityId || !!propsTeam});
    const team = (propsTeam || result.data?.entity);
    const participants = team?.participants;

    const segmentedTeam = React.useMemo(() => {
      if (!participants) {
        return {};
      }

      const groupValues:Record<string, Member[]> = {};
      
      participants.forEach(m => {
        const groupNum = group(m);
        m.role = normalizedRole(m.role);
        groupValues['g' + groupNum] ||= [];
        groupValues['g' + groupNum].push(m);
      });

      return groupValues;
    }, [participants]);

    return {entityKind, entityId, team, participants, segmentedTeam}
  }

  return render();
}

UnifiedTeam.defaultProps = {
  onNavigation: 'prompt-dirty',
  groups: [{
    add:'modal',
    defaultRecord:{kind: 'invite', role: MemberRole.Member},
    contacts: false
  }],
  group: () => 0
}

// we always want to get role fields regardless of whether they are displayed
export const allTeamFields = allTeamCols.filter(c => c !== 'role' && c !== 'resendInvite').map(c => 'participants.' + c);

export function getTeamFielsToOmit(colsToUse:string[]) {
  return difference(allTeamFields, colsToUse.map(c => c == 'resendInvite' ? 'sentAt' : c).map(c => 'participants.' + c));
}

const roleOptions = [{
  label: 'Administrator',
  value: MemberRole.Admin
}, {
  label: 'Member',
  value: MemberRole.Member
}]

const normalizedRoles:any = {
  [MemberRole.Admin.toLocaleLowerCase()]: MemberRole.Admin,
  [MemberRole.Member.toLocaleLowerCase()]: MemberRole.Member,
  [OrganizerRole.Coordinator.toLocaleLowerCase()]: MemberRole.Admin,
  [OrganizerRole.SiteMember.toLocaleLowerCase()]: MemberRole.Member,
  [CompanyRole.CompanyAdmin.toLocaleLowerCase()]: MemberRole.Admin,
  [CompanyRole.CompanyMember.toLocaleLowerCase()]: MemberRole.Member,
  [FamilyRole.Parent.toLocaleLowerCase()]: MemberRole.Admin
}

function normalizedRole(role:string) {
  return normalizedRoles[role.toLocaleLowerCase()] || role;
}

const roleValues:any = {
  [MemberRole.Admin]: 1,
  [MemberRole.Member]: 0
}
